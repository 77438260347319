import Typed from 'react-typed';

function Home() {
    return (
        <>
            <div className="description">
            <Typed
                strings={[
                    'Hi.',
                    'I\'m Kelfitas.',
                    'I like building and breaking stuff.',
                    'Welcome to my home.'
                ]}
                typeSpeed={60}
            />
            </div>
            <div
                style={{
                    maxWidth: '400px',
                    margin: 'auto',
                }}
                className="content"
            >
                <ul>
                    <li><a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/kelfitas/">Linkedin</a></li>
                    <li><a rel="noreferrer" target="_blank" href="https://github.com/kelfitas">Github</a></li>
                    <li><a rel="noreferrer" target="_blank" href="https://hackerone.com/kelfitas">Hacker1</a></li>
                    <li><a rel="noreferrer" target="_blank" href="https://twitter.com/kelfitas">Twitter</a></li>
                </ul>
            </div>
        </>
    );
}

export default Home;