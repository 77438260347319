import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Home from './pages/Home';
// import Menu from './components/Menu';
import Footer from "./components/Footer";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                {/* <Menu /> */}
                <div class="content">
                    <Routes>
                        <Route index element={<Home />} />
                    </Routes>
                </div>
                <Footer />
            </BrowserRouter>
        </div>
    );
}

export default App;
